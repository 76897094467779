import moment from "moment/min/moment-with-locales";

export class Utilities {
    date(value, format = "ddd, DD.MM.YYYY", fallback = "") {
        if (value) {
            return moment(String(value)).format(format);
        }
        return fallback;
    }

    time(value, format = "HH:mm") {
        return this.date(value, format);
    }

    days(start, end) {
        return moment(end).diff(moment(start), "days");
    }

    number(value, decimal = 2, currency = "") {
        if (value == null) return "";
        let val = (value / 1).toFixed(decimal).replace(".", ",");
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "") + " " + currency;
    }

    money(value, decimal = 2) {
        return this.number(value, decimal, "€");
    }

    humanize(value) {
        if (value) {
            return moment(String(value)).fromNow();
        }
    }

    toNumber(value) {
        return value.replace(".", "").replace(",", ".");
    }

    redirect(value) {
        window.location = value;
    }

    // is date are queal
    isDateEqual(value, value2) {
        var value2 = moment(value2);
        return moment(value).isSame(value2);
    }

    ratingText(value) {
        if (value > 80) {
            return "Odlično";
        } else if (value > 60) {
            return "Dobro";
        } else if (value > 40) {
            return "Zadovoljivo";
        } else if (value > 20) {
            return "Slabo";
        } else {
            return "Zelo slabo";
        }
    }
}

const utils = new Utilities();

export function toNumber(value) {
    return utils.toNumber(value);
}

export function toLocaleNumber(value) {
    return utils.number(value);
}

export function redirect(value) {
    return utils.redirect(value);
}

export function date(value, format, fallback) {
    return utils.date(value, format, fallback);
}

export function days(start, end) {
    return utils.days(start, end);
}

export function isDateEqual(value, value2) {
    return utils.isDateEqual(value, value2);
}

export const UtilitiesVue = {
    install(app) {
        app.config.globalProperties.$date = (value, format, fallback) => utils.date(value, format, fallback);
        app.config.globalProperties.$time = (value, format) => utils.time(value, format);
        app.config.globalProperties.$number = (value) => utils.number(value);
        app.config.globalProperties.$money = (value, decimal) => utils.money(value, decimal);
        app.config.globalProperties.$humanize = (value) => utils.humanize(value);
        app.config.globalProperties.$initials = (first, second) => utils.initials(first, second);
        app.config.globalProperties.$redirect = (value) => utils.redirect(value);
        app.config.globalProperties.$days = (start, end) => utils.days(start, end);
        app.config.globalProperties.$ratingText = (value) => utils.ratingText(value);
        app.config.globalProperties.$utils = utils;
        app.provide("utils", utils);
    },
};

//import { toNumber } from "@/Helpers/Utilities";
//const utils = inject("utils");